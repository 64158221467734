<template>
  <div class="main_container">
    <div class="row mb-3">
      <div class="text-left col-12">
        <router-link :to="'/operation/operationsBancaires'">
          Opérations bancaires>
        </router-link>
        <span>Liste des transactions</span>
      </div>
    </div>
    <h2 class="page_title text-center">LISTE DES TRANSACTIONS</h2>
    <div class="row">
      <div class="col-12">
        <button class="btn btn-primary"
                @click="loadGroupedTransactions(true)"
                v-bind:class="{ 'lt-btn-active': groupedTransactions, 'lt-btn-inactive': !groupedTransactions }">
          {{ long }} /{{nbCateg}} transactions <br />
          non-catégorisées
        </button>
        <button class="btn btn-primary ml-5"
                @click="loadGroupedTransactions(false)"
                v-bind:class="{ 'lt-btn-active': !groupedTransactions, 'lt-btn-inactive': groupedTransactions }">
          Voir toutes les <br />
          transactions <br />
          catégorisées
        </button>
      </div>
    </div>
    <div class="row">
      <Notif :notif="notif" />
    </div>
    <div class="pt-5">
      <table class="table tablestyle_3 table-striped">
        <thead>
          <tr>
            <th scope="col"
                style="padding-left:20px">
              Banque
            </th>
            <th scope="col">
              Date
            </th>
            <th scope="col">
              Libellé opération
            </th>
            <th scope="col">
              Débit
            </th>
            <th scope="col">
              Crédit
            </th>
          </tr>
        </thead>
        <!-- liste des transactions non catégorisées -->
        <tbody v-if="groupedTransactions == true">
          <tr v-if="long == 0">
            <td class="text-center"
                colspan="5">
              Aucune transaction non catégorisé enregistré
            </td>
          </tr>
          <tr v-for="transaction in transactions"
              :key="transaction.id">
            <td>
              <label>
                <input type="checkbox"
                       @change="checkCheckbox($event, transaction)" />
                <span class="ml-2">
                  {{ comptesTransactions.transactions[0].banque.denomination }}
                </span>
              </label>
            </td>
            <td>
              {{ transaction.operation }}
            </td>
            <td>
              {{ transaction.details_releve }}
            </td>
            <td>
              {{ Math.round(transaction.debit).toLocaleString() }}
            </td>
            <td>
              {{ Math.round(transaction.credit).toLocaleString() }}
            </td>
          </tr>
        </tbody>
        <!-- Liste des transactions non catégorisées -->
        <tbody v-else>
          <tr v-if="longCat == 0">
            <td class="text-center"
                colspan="5">
              Aucune transaction enregistré
            </td>
          </tr>
          <tr v-for="transaction in transactionsCat"
              :key="transaction.id"
              @click="launchModal(transaction)">
            <td>
              <label>
                <!-- <input type="checkbox"
                       @change="checkCheckbox($event, transaction)" />
                <span class="ml-2"> -->
                {{ comptesTransactions.transactions[0].banque.denomination }}
                <!-- </span> -->
              </label>
            </td>
            <td>
              {{ transaction.operation }}
            </td>
            <td>
              {{ transaction.details_releve }}
            </td>
            <td>
              {{ Math.round(transaction.debit).toLocaleString() }}
            </td>
            <td>
              {{ Math.round(transaction.credit).toLocaleString() }}
            </td>
          </tr>
        </tbody>
      </table>

      <div v-if="groupedTransactions == true"
           class="row pagination">
        <div v-if="comptesTransactions.pagination"
             class="col-md-1 mx-auto text-center">
          <span v-if="comptesTransactions.pagination.precedent"
                class="badge badge-pill badge-info"
                @click="pIndex--, paging(comptesTransactions.pagination.precedent)"> &#60; </span>
          {{ pIndex }}
          <span v-if="comptesTransactions.pagination.suivant"
                class="badge badge-pill badge-info"
                @click="pIndex++, paging(comptesTransactions.pagination.suivant)"> &#62; </span>
        </div>
      </div>
      <div v-else
           class="row pagination">
        <div v-if="transactionsCat.pagination"
             class="col-md-1 mx-auto text-center">
          <span v-if="transactionsCat.pagination.precedent"
                class="badge badge-pill badge-info"
                @click="index--, pagingCat(transactionsCat.pagination.precedent)"> &#60; </span>
          {{ index }}
          <span v-if="transactionsCat.pagination.suivant"
                class="badge badge-pill badge-info"
                @click="index++, pagingCat(transactionsCat.pagination.suivant)"> &#62; </span>
        </div>
      </div>
    </div>

    <!-- Button trigger modal -->
    <button v-show="showing"
            type="button"
            class="btn btn-primary"
            ref="modal_button"
            data-toggle="modal"
            data-target="#exampleModal">
      Launch demo modal
    </button>
    <!----------------------------------------- Modal de catégorisation ------------------------------------------->
    <!-- Modal -->
    <div class="modal fade "
         id="exampleModal"
         tabindex="-1"
         aria-labelledby="exampleModalLabel"
         aria-hidden="true">
      <div class="modal-dialog modal-lg">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title"
                id="exampleModalLabel">Catégorisation de la transaction</h5>
            <button type="button"
                    class="close"
                    data-dismiss="modal"
                    aria-label="Close">
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <!-------------------------- Corps du Modal ------------------------------->
          <div v-if="!cat"
               class="modal-body">
            <form>
              <div class="form-group row">
                <div class="col-md-2">
                  <label for="">Banque:</label>
                </div>
                <div class="col-md-5"
                     v-if="selectedTransaction">{{ comptesTransactions.transactions[0].numero }} ({{ comptesTransactions.transactions[0].banque.denomination }})</div>
              </div>
              <div class="form-group row">
                <div class="col-md-2">
                  <label for="">Date:</label>
                </div>
                <div class="col-md-2"
                     v-if="selectedTransaction">
                  {{ selectedTransaction.operation }}
                </div>
                <div class="col-md-3">
                  <label for="">Libellé opération:</label>
                </div>
                <div class="col-md-5"
                     v-if="selectedTransaction">
                  {{ selectedTransaction.details_releve }}
                </div>
              </div>
              <div class="form-group row">
                <div class="col-md-2">
                  <label for="">Débit:</label>
                </div>
                <div class="col-md-4"
                     v-if="selectedTransaction">{{ Math.round(selectedTransaction.debit).toLocaleString() }} FCFA</div>
                <div class="col-md-2">
                  <label for="">Crédit:</label>
                </div>
                <div class="col-md-4"
                     v-if="selectedTransaction">{{ Math.round(selectedTransaction.credit).toLocaleString() }} FCFA</div>
              </div>
              <div class="form-group row">
                <div class="col-md-6">
                  <label for="">Référence</label>
                  <input class="form-control"
                         type="text"
                         name=""
                         id=""
                         v-model="form.reference" />
                  <span v-if="form.errors().has('reference')"
                        v-text="form.errors().get('reference')"
                        class="errorMsg"> </span>
                </div>
                <div class="col-md-6">
                  <label for="">Commentaires:</label>
                  <input class="form-control"
                         type="text"
                         name=""
                         id=""
                         v-model="form.commentaires" />
                  <span v-if="form.errors().has('commentaires')"
                        v-text="form.errors().get('commentaires')"
                        class="errorMsg"> </span>
                </div>
              </div>
              <div class="form-group row">
                <div class="col-md-6">
                  <label for="">Type</label>
                  <select class="form-control"
                          name=""
                          id=""
                          v-model="form.type"
                          @change="getSousType(form.type)">
                    <option v-for="(type, tkey) in typeTransactions"
                            :key="tkey"
                            :value="type.type">{{ type.type }}</option>
                  </select>
                  <span v-if="form.errors().has('type')"
                        v-text="form.errors().get('type')"
                        class="errorMsg"> </span>
                </div>
                <div class="col-md-6">
                  <label for="">Sous-type</label>
                  <select v-if="form.type == 'LEVEE_FONDS'"
                          class="form-control"
                          name=""
                          id=""
                          v-model="form.sous_type"
                          @change="getChamps(form.sous_type)">
                    <option :value="sousTypeList.sous_type">{{ sousTypeList.sous_type }}</option>
                  </select>
                  <select v-else
                          class="form-control"
                          name=""
                          id=""
                          v-model="form.sous_type"
                          @change="getChamps(form.sous_type)">
                    <option v-for="(sousType, skey) in sousTypeList"
                            :key="skey"
                            :value="sousType.sous_type">{{ sousType.sous_type }}</option>
                  </select>
                  <span v-if="form.errors().has('sous_type')"
                        v-text="form.errors().get('sous_type')"
                        class="errorMsg"> </span>
                </div>
              </div>
              <!-- Debut partie des champs conditionnels -->
              <!-- if champList is an array -->
              <fieldset v-if="champList[0]"
                        class="sous-item pt-3 pl-1">
                <!-- if champList is not from Levee_fonds -->
                <template v-if="form.sous_type != 'LEVEE_FONDS'">
                  <div class="form-group row">
                    <div v-for="(champ, chkey) in champList"
                         :key="chkey"
                         class="col-md-4">
                      <label for="">{{ champ.champ }}</label>
                      <select v-if="champ.valeurs.length != 0"
                              class="form-control"
                              name=""
                              id=""
                              v-model="reform[champ.champ]">
                        <option v-for="(valeur, vakey) in champ.valeurs"
                                :key="vakey"
                                :value="valeur">{{ valeur }}</option>
                      </select>
                      <input v-else
                             class="form-control"
                             type="text"
                             name=""
                             id=""
                             v-model="reform[champ.champ]" />
                    </div>
                  </div>
                </template>
                <!-- if champList is from levee_fonds -->
                <template v-else>
                  <div class="form-group row">
                    <div class="col-md-4">
                      <label for="">Facilité :</label>
                    </div>
                    <div class="col-md-8">
                      <select class="form-control"
                              name=""
                              id=""
                              v-model="reform[champList[0].champ]">
                        <option v-for="(valeur, vakey) in champList[0].valeurs"
                                :key="vakey"
                                :value="valeur">{{ valeur }}</option>
                      </select>
                    </div>
                  </div>
                  <div class="form-group row">
                    <div class="col-md-4">
                      <label for="">Tranche :</label>
                    </div>
                    <div class="col-md-8">
                      <div class="form-group">
                        <input type="number"
                               class="form-control"
                               name=""
                               id=""
                               v-model="reform['tranche_a']" />
                      </div>
                    </div>
                  </div>
                </template>
              </fieldset>
              <!-- fin if champList is an array -->
              <!-- if champList is an object -->
              <fieldset v-if="champList['champ']"
                        class="sous-item pt-3 pl-1">
                <div class="form-group row">
                  <div class="col-md-4">
                    <label for="">{{ champList.champ }}</label>
                  </div>
                  <div v-if="champList.valeurs[0]"
                       class="col-md-8">
                    <select class="form-control"
                            name=""
                            id=""
                            v-model="reform[champList.champ]">
                      <option v-for="(valeur, vakey) in champList.valeurs"
                              :key="vakey"
                              :value="valeur">{{ valeur }}</option>
                    </select>
                  </div>
                  <div v-else
                       class="col-md-8">
                    <input class="form-control"
                           type="text"
                           name=""
                           id=""
                           v-model="reform[champList.champ]" />
                  </div>
                </div>
              </fieldset>
              <!-- fin if champList is an object -->
              <!-- Fin partie des champs conditionnels -->
            </form>
          </div>

          <!------------------------------------- fin modal body --------------------------------------------->
          <Categorized v-if="cat"
                       :selectedTransaction="selected"
                       :compte="comptesTransactions" />
          <div v-if="!cat"
               class="modal-footer">
            <button type="button"
                    class="btn btn-secondary"
                    ref="close_modal"
                    data-dismiss="modal">Annuler</button>
            <button type="button"
                    class="btn btn-primary"
                    @click="submit">Valider</button>
          </div>
        </div>
      </div>
    </div>
    <!--endModal--->
  </div>
</template>

<script>
import { mapActions, mapGetters, mapMutations } from "vuex"
import Notif from "@/components/shared/Toast"
import Categorized from "./categorisation/Categorized"
import form from "vuejs-form"
export default {
  name: "ListeDesTransactions",
  data: () => ({
    notif: {
      activated: "",
      type: "",
      message: ""
    },
    reform: "",
    form: form({
      type: "",
      sous_type: "",
      reference: "",
      commentaires: "",
      champ: "",
      valeur: ""
    })
      .rules({
        type: "required",
        sous_type: "required",
        reference: "required",
        commentaires: "required"
      })
      .messages({
        type: "Ce champs est requis!",
        sous_type: "Ce champs est requis!",
        reference: "Ce champs est requis!",
        commentaires: "Ce champs est requis!"
      }),
    transactions: [],
    transactionsCat: [],
    transData: {
      limite: 1000,
      apres: "MQ==",
      en_attente: 2
    },
    longueur: 0,
    longCat: 0,
    long: 0,
    nbCateg:0,
    sousTypeList: [],
    champList: [],
    valeurList: [],
    pIndex: 1,
    index: 1,
    id: "",
    selected: "",
    cat: false,
    showing: false,
    groupedTransactions: true,
    selectedTransaction: null
  }),
  components: {
    Notif,
    Categorized
  },
  watch: {
    successTransaction() {
      if (this.successTransaction) {
        this.$refs["close_modal"].click()
        this.notif.message = "Catégorisation effectuée"
        this.notif.type = "toast-success"
        this.notif.activated = "activated"

        setTimeout(
          function() {
            this.notif.activated = ""
            this.setSucces("")
          }.bind(this),
          3000
        )
        location.reload()
      }
    },
    errorsTransaction() {
      if (this.errorsTransaction) {
        this.notif.message = this.errorsTransaction
        this.notif.type = "toast-danger"
        this.notif.activated = "activated"
        setTimeout(
          function() {
            this.notif.activated = ""
            this.setErrors("")
          }.bind(this),
          3000
        )
      }
    },
    comptesTransactions() {

      if (this.comptesTransactions) {
        this.comptesTransactions.transactions.forEach(transaction => {
          if (transaction.type == null) {
            this.long += 1
            this.transactions.push(transaction)
          } else {
            this.longCat += 1

            this.transactionsCat.push(transaction)
          }
        })


      }
    },
    typeTransactions() {
      this.valDecaissements(this.transData)
    }
  },
  created() {
    this.loadTypeTransactions()
    this.loadBanktransactions(this.$route.params.id)
    this.loadBankAccounts()
    //Calcul du montant total
    this.calclulNbCategories()
  },
  computed: {
    ...mapGetters(["comptesTransactions", "successTransaction", "errorsTransaction", "typeTransactions","decaissements","comptes"])
  },
  methods: {
    ...mapActions(["updateTransaction", "loadTypeTransactions", "loadBanktransactions","valDecaissements","pageTransactions","pageTransactionsCat","loadBankAccounts"]),
    ...mapMutations(["setSucces", "setErrors"]),
    activate() {},
    loadGroupedTransactions(status) {
      this.groupedTransactions = status

    },
    checkCheckbox(event, transaction) {
      this.selectedTransaction = transaction
      this.id = this.selectedTransaction.id
      if (event.target.checked) {

        this.$refs["modal_button"].click()
      }
      this.cat = false

    },
    launchModal(transaction) {
      this.selectedTransaction = transaction
      this.id = this.selectedTransaction.id
      this.selected = Object.keys(this.selectedTransaction).reduce((newObj, key) => {
        const value = this.selectedTransaction[key]
        if (value !== null) {
          newObj[key] = value
        }
        return newObj
      }, {})

      this.$refs["modal_button"].click()
      this.cat = true

    },
    paging(url) {
      if (this.pIndex == 0) {
        this.pIndex = 1
      }
      this.goodUrl = url.substr(1)
      this.pageTransactions(this.goodUrl)
    },
    pagingCat(url) {
      if (this.pIndex == 0) {
        this.pIndex = 1
      }
      this.goodUrl = url.substr(1)
      this.pageTransactionsCat(this.goodUrl)
    },
    // methode pour lancer les tris
    filtrer() {
      if (this.stat) {
        this.setTransactions("")
        this.loadTransactionsSC(this.transData)
      } else {
        this.setTransactionsCat("")
        this.loadTransactions(this.transData)
      }
      this.transData.libelle=null
      this.transData.order="asc"
      this.transData.tri=null
    },
    getSousType(type) {
      this.sousTypeList = []
      this.typeTransactions.forEach(typeT => {
        if (typeT.type == type) {
          this.sousTypeList = typeT.sous_types
        }
      })

    },
    getChamps(sousType) {

      this.purifier(this.form)
      this.champList = []
      this.valeurList = []
      if (sousType == "LEVEE_FONDS") {
        this.champList = this.sousTypeList.champs
      } else if (sousType == "PROGRAMME_ENTRETIEN_ROUTIER") {
        this.champList = []
        this.valeurList = []
        var tmp
        tmp = []
        this.decaissements.donnees.forEach(decaiss => {
          tmp.push(decaiss.decompte.id)
        })
        //this.champList = tmp //J'ai essayé de passé une liste mais ça me renvoi une erreur

        this.champList.push({ champ: "decaissement", valeurs: tmp })
      } else {
        this.sousTypeList.forEach(sousT => {
          if (sousT.sous_type == sousType) {
            this.champList = sousT.champs
          }
        })
      }

    },
    getValeurs(champ) {
      this.valeurList = []
      if (champ == "gare_pesage") {
        this.valeurList = this.champList.valeurs
      } else {
        this.champList.forEach(champT => {
          if (champT.champ == champ) {
            this.valeurList = champT.valeurs
          }
        })
      }

    },
    purifier(form) {
      this.reform = Object.keys(form).reduce((acc, key) => {
        if (key === "commentaires" || key === "reference" || key === "sous_type" || key === "type") {
          acc[key] = form[key]
        }
        return acc
      }, {})
    },
    submit() {
      this.form.validate()
      if (
        !this.form
          .validate()
          .errors()
          .any()
      ) {
        this.updateTransaction({ id: this.id, data: this.reform })
      }
    },
    calclulNbCategories(){
      this.comptes.donnees.forEach(compte =>{
        this.nbCateg+=compte.transactions_non_categorisees

      })
    }
  }
}
</script>

<style>
@import "./operationsBancaires.css";
</style>

<style scoped>
.lt-btn-active {
  width: 188.75px;
  height: 59.01px;
  background: #0097a9;
  font-family: Poppins;
  font-style: normal;
  font-weight: bold;
  font-size: 12px;
  line-height: 18px;
  text-align: center;
  letter-spacing: -0.005em;
  color: #000000;
}
.lt-btn-inactive {
  width: 188.75px;
  height: 59.01px;
  background: white;
  font-family: Poppins;
  font-style: normal;
  font-weight: bold;
  font-size: 12px;
  line-height: 18px;
  text-align: center;
  letter-spacing: -0.005em;
  color: rgba(0, 0, 0, 0.25);
}

.btn:focus {
  outline: none !important;
}
</style>
